import React, { useEffect, useState } from 'react';
import { supabase } from './Components/Authentication/utils';
import './App.css';
import NBA_GPT from './NBA_GPT';
import { ChakraProvider } from '@chakra-ui/react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { SignUpPage } from './Components/Authentication/SignUpPage';
import { LoginPage } from './Components/Authentication/LoginPage';
import { VerifyEmail } from './Components/Authentication/VerifyEmail';
import { SignUpPagePhone } from './Components/Authentication/SignUpPagePhone';
import { LoginPagePhone } from './Components/Authentication/LoginPagePhone';
import CryptoJS from 'crypto-js';

function App() {
  const [session, setSession] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (data && data.session) {
        setSession(data.session);
        setUserId(data.session.user.id);
      } else {
        setSession(null);
        setUserId(null);
      }
    
      if (error) {
        console.error("error", error);
      }
    
      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          setSession(session);
          setUserId(session ? session.user.id : null);
        }
      );
    
      return () => {
        authListener.unsubscribe();
      };
    }
    checkSession();
  }, []);

  // const updateSession = (newSession) => {
  //   setSession(newSession);
  //   localStorage.setItem('session', JSON.stringify(newSession));
  // };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log(error.message);
    }
    setSession(null); // Clear session state
    setUserId(null);  // Clear user id state
    window.location.href = '/login';
  };

  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/login" element={!session ? <LoginPagePhone /> : <Navigate to="/" />} />
          <Route path="/signup" element={!session ? <SignUpPagePhone /> : <Navigate to="/" />} />
          {/* <Route path="/verify" element={session ? <VerifyEmail /> : <Navigate to="/signup" />} /> */}
          <Route path="/" element={session ? <NBA_GPT userId={userId} handleSignOut={handleSignOut} /> : <Navigate to="/signup" />} />
        </Routes>
      </Router>
    </ChakraProvider>

  );
}

export default App;