import React, { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Alert,
  AlertIcon
} from '@chakra-ui/react';
import { supabase } from './utils';
import { useNavigate } from 'react-router-dom';

const redirectToUrl = '/'

export const SignUpPagePhone = (props) => {
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();

  const handleOTPRequest = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Format the phone number to E.164 format if needed
    let formattedPhone = phone;
    if (!phone.startsWith('+')) {
      if (phone.length === 10) {
        // Likely a US number without the country code
        formattedPhone = '+1' + phone;
        setPhone('1' + phone);
      } else if (phone.length === 11 && phone.startsWith('1')) {
        // Likely a US number with the country code
        formattedPhone = '+' + phone;
      }
    }

    try {
      const { user, error } = await supabase.auth.signInWithOtp({
        phone: formattedPhone,
      });

      if (error) {
        setError(`Error: ${error.message}`);
      } else { 
        const { data, error } = await supabase
        .from('profiles')
        .insert([
          { full_name: fullName, phone: phone }
        ]);
      }

      setOtpRequested(true);
      setIsLoading(false);
    } catch (error) {
      setError(`Error: ${error.message}`);
      setIsLoading(false);
    }
  };


  const handleOTPVerification = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { error } = await supabase.auth.verifyOtp({
        phone: phone,
        token: otp,
        type: 'sms',
      });

      if (error) {
        throw new Error(error.message);
      }

      // Refresh to work around non-persistent OTP session
      const { data, error: refreshError } = await supabase.auth.refreshSession();

      if (refreshError) {
        throw new Error(refreshError.message);
      }

      props.updateSession(data.session.access_token);

      navigate(redirectToUrl);
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'} py={6}>
            Create your NBA GPT account
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            {error && (
              <Alert status="error">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <FormControl id="full-name" isRequired>
              <FormLabel>Full Name</FormLabel>
              <Input type="text" value={fullName} onChange={e => setFullName(e.target.value)} />
            </FormControl>
            <FormControl id="phone" isRequired>
              <FormLabel>Phone Number</FormLabel>
              <Input type="tel" value={phone} onChange={e => setPhone(e.target.value)} />
            </FormControl>
            <Button
              isLoading={isLoading}
              loadingText="Requesting OTP"
              colorScheme="teal"
              size="md"
              onClick={handleOTPRequest}>
              Continue
            </Button>

            {otpRequested && (
              <>
                <FormControl id="otp" isRequired>
                  <FormLabel>Enter your verification code</FormLabel>
                  <Input type="text" value={otp} onChange={e => setOtp(e.target.value)} />
                </FormControl>
                <Button
                  isLoading={isLoading}
                  loadingText="Verifying OTP"
                  colorScheme="blue"
                  size="md"
                  onClick={handleOTPVerification}>
                  Verify
                </Button>
              </>
            )}

            <Stack pt={6}>
              <Text align={'center'}>
                Already have an account? <Link color={'blue.400'} href="/login/">Sign in</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};
