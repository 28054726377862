import { Flex, Box, Stack, Heading, Text, Link, useColorModeValue, Spacer } from '@chakra-ui/react';

export const VerifyEmail = () => {

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'xl'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'} py={6}>
            Verify Your Email
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <Text fontSize={'xl'} textAlign={'center'}>
            Thanks for signing up for NBA GPT! Please check your email for a confirmation link to complete your registration.
            </Text>
            <Spacer />
            <Text fontSize={'sm'} textAlign={'center'}>
              If you didn't receive an email, please check your spam folder or contact <Link href="mailto:ianlim@stanford.edu">ianlim@stanford.edu</Link>.
            </Text>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}